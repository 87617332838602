import React from 'react'
import { graphql } from 'gatsby'

import Top10ListDup from '../components/top10dup'
import Layout from '../components/layout'
import Seo from "../components/seo"

const WccategoryTemplate = ({
  data: { categoryArray, relatedarticleArray, top10listArray },
}) => {
  const category = categoryArray.edges[0].node

  return (
    <Layout>
      <Seo title={category.title + " for " + (new Date().getFullYear())} description={category.metadescr} />
      <Top10ListDup hostingid={category.hostingtypeid} key="11" />
      <hr />
    </Layout>
  )
}

export default WccategoryTemplate

export const pageQuery = graphql`
  query CategById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: Int!
  ) {
    # selecting the current category by id
    categoryArray: allMysqlHostingcategories(filter: { hostingtypeid: { eq: $id } }) {
      edges {
        node {
          id
          hostingtypeid
          title
          metatitle
          metadescr
          description
        }
      }
    }
  }
`
